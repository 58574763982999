import { useEffect, useState } from "react"
import { Button, Modal, Card } from "react-bootstrap";
import { getMatchesForJob as getMatchesForJobApi } from "../../../api/private/matches"

const JobMatchesModal = ({_job_id, _show, _onResp}) => {
const [matches, setMatches] = useState([])
const [loading, setLoading] = useState(false)

useEffect(() => {
    getMatchesForJob(_job_id)
}, [_job_id, _show])

function getMatchesForJob(_job_id){
    if(_job_id === null){return;}
    setLoading(true)
    getMatchesForJobApi(_job_id, '').then((_resp) => {
        const records = _resp.data.seekers
        setMatches(records)
    }).catch((_resp) => {

    }).finally(() => {
        setLoading(false)
    })
}

function onOpenClick(_user) {
    _user['usertype'] = 1
    _user['_id'] = _user['seekerId']
    localStorage.setItem("myContact", JSON.stringify(_user));
    window.open('/detail-seeker', '_blank');
}

return <>
    <Modal className="fade" show={_show} size="lg">
        <Modal.Header>
            <h4 className="mb-0 pb-0">Job Matches</h4>
            <div className="d-flex justify-content-between align-items-center">
                <i onClick={() => getMatchesForJob(_job_id)} className="mdi mdi-refresh fs-3 text-info" style={{'cursor': 'pointer'}}></i>
                <Button onClick={() => _onResp('close', null)} variant="" className="btn-close"></Button>
            </div>
        </Modal.Header>
        <Modal.Body className="p-2">
            <div className="table-responsive">
                {loading === false?
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Fullname</th>
                                <th>Percentage</th>
                                <th>CreatedAt</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map((match) =>
                                <tr key={match['createdAt']}>
                                    <td>{match['details']['fullName']}</td>
                                    <td>{match['percentage'].toFixed(2)} %</td>
                                    <td>{match['createdAt'].substring(0, 16)}</td>
                                    <td><span onClick={() => onOpenClick(match)} className="text-info" type='button'>Open</span></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                : <h1 className="text-center opacity-60">Loading...</h1> }
            </div>
        </Modal.Body>
    </Modal>
</>
}
export default JobMatchesModal