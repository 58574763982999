import { useState, useEffect } from "react";
import { Button, Modal, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { getPromptsById as getPromptsByIdAPi, 
    updatePrompt as updatePromptApi } from "../../../api/private/ai_prompts";

const AiPromptModal = ({_doc_id, _onResp}) => {
const baseSaveText = 'Save';
const [btnSaveText, setBtnSaveText] = useState(baseSaveText);
const [recordDetails, setRecordDetails] = useState(null);
const [showModel, setShow] = useState(false)

useEffect(() => {
    if(![null, undefined].includes(_doc_id)){
        setShow(true)
        getPromptsById()
    }else{
        setShow(false)
        setRecordDetails(null)
    }
}, [_doc_id])

function getPromptsById(){
    getPromptsByIdAPi(_doc_id).then((_resp) => {
        const record = _resp.data.data
        setRecordDetails(record)
    }).catch(() => {

    })
}

function onUpdateClick(){
    setBtnSaveText('Saving...')
    updatePromptApi(recordDetails).then((_resp) => {
        console.log(_resp.data)
    }).catch((_resp) => {

    }).finally(() => {
        setBtnSaveText(baseSaveText)
    })
}

function onKeyValueChange(_key, _value){
    let tmp = {...recordDetails}
    tmp[_key] = _value
    setRecordDetails(tmp)
}

return (<>
<Modal className="fade" show={showModel} size="lg">
    <Modal.Header>
        {recordDetails !== null ?
            <Modal.Title className="text-capitalize">{recordDetails['key'].replaceAll('_', ' ')}</Modal.Title>
        : null}
        <div>
            <Button onClick={() => _onResp('close', null)} variant="" className="btn-close"></Button>
        </div>
    </Modal.Header>
    <Modal.Body>
        {recordDetails !== null?
            <textarea onChange={(e) => onKeyValueChange('prompt', e.target.value)} value={recordDetails['prompt']} style={{height: '300px'}} className="form-control border"/>
        : null}
    </Modal.Body>
    <Modal.Footer>
        <Button onClick={() => _onResp('close', null)} variant="danger light">Close</Button>
        <Button onClick={onUpdateClick} disabled={btnSaveText !== baseSaveText} variant="primary">{btnSaveText}</Button>
    </Modal.Footer>
</Modal>
</>)
}

export default AiPromptModal