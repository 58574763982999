import { useEffect, useState } from "react";
import AiPromptModal from "../modals/ai-prompt-modal";
import { getPromptKeys as getPromptKeysApi } from "../../../api/private/ai_prompts";
const AiPrompsPage = () => {
const [tableData, setTableData] = useState([])
const [selectedPromptId, setSelectedPromptId] = useState(null)

useEffect(() => {
    getPromptKeys()
}, [])

function getPromptKeys(){
    getPromptKeysApi().then((_resp) => {
        if(_resp.data.data.Result === 'OK'){
            const records = _resp.data.data.Records
            setTableData(records)
        }
    }).catch((_resp) => {

    }).finally(() => {

    })
}

function onAiPromptModalResp(_cmd, _value){
    if(_cmd === 'close'){
        setSelectedPromptId(null)
    }
}

return (<>
    <table className="table table-sm table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th>Created By</th>
                <th>Created On</th>
            </tr>
        </thead>
        <tbody>
            {tableData.map((obj, _index) => 
                <tr onClick={() => { setSelectedPromptId(obj['_id']) }} key={_index}>
                    <td className="text-capitalize">{obj['key'].replaceAll('_', ' ')}</td>
                    <td className="text-capitalize">{obj['created_by']['username']}</td>
                    <td className="text-capitalize">{obj['created_on'].substring(0, 16)}</td>
                </tr>
            )}
        </tbody>
    </table>
    <AiPromptModal _doc_id={selectedPromptId} _onResp={onAiPromptModalResp}/>
</>)
}

export default AiPrompsPage