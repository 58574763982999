import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { getJobById as getJobByIdApi, 
    updateJob as updateJobApi,
    setPercentageAndPointsForAllJobs as setPercentageAndPointsForAllJobsApi } from "../../../api/private/jobs";
import JobMatchesModal from "../modals/job-matches";
import { toast } from "react-toastify";

const JobDetailsPage = () => {
const { jobid } = useParams();
const baseSaveText = 'Save';
const [btnSaveText, setBtnSaveText] = useState(baseSaveText);
const [mounted, setMounted] = useState(false);
const [loading, setLoading] = useState(false)
const [jobDetails, setJobDetails] = useState(null)
const [showNewRoleInput, setShowNewRoleInput] = useState(false);
const [newRole, setNewRole] = useState("");
const [showNewEducationInput, setShowNewEducationInput] = useState(false);
const [newEducation, setNewEducation] = useState("");
const [showNewSoftSkillInput, setShowNewSoftSkillInput] = useState(false);
const [newSoftSkill, setNewSoftSkill] = useState({ option: "", exp: "", explevel: "" });
const [newSoftSkillExp, setNewSoftSkillExp] = useState({ min: 0, max: 2 });
const [showNewHardSkillInput, setShowNewHardSkillInput] = useState(false);
const [newHardSkill, setNewHardSkill] = useState({ option: "", exp: "", explevel: "" });
const [newHardSkillExp, setNewHardSkillExp] = useState({ min: 0, max: 2 });
const [showJobMatchesModal, setShowJobMatchesModal] = useState(false)

useEffect(() => {
    if (!mounted) {
        setMounted(true);
        loadJobById()
    }
}, [mounted]);

function loadJobById(){
    setLoading(true)
    getJobByIdApi(jobid).then((_resp) => {
        if(_resp.data.message.includes('successfull')){
            const records = _resp.data.result
            if(records.length > 0){
                setJobDetails(records[0])
            }else{

            }
        }else{}
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {
        setLoading(false)
    })
}

function onUpdateClick() {
    setBtnSaveText('Saving');
    funReMatchingAlgorythm()
    updateJobApi(jobDetails).then((_resp) => {
        if (_resp.data.msg.includes('successfully')) {
            toast.success('updated')
        } else {
            // Optionally handle error case
        }
    }).catch((_resp) => {
        console.error(_resp);
    }).finally(() => {
        setBtnSaveText(baseSaveText);
    });
}

function funReMatchingAlgorythm(){
    const job_id = jobDetails['_id']
    const job_user_id = jobDetails['userId']
    setPercentageAndPointsForAllJobsApi(job_id, job_user_id).then((_resp) => {
        console.log(_resp)
    }).catch((_resp) => {
        console.log(_resp)
    }).finally(() => {

    })
}

function onKeyValueChange(_key, _value) {
    let tmp = { ...jobDetails };
    tmp[_key] = _value;
    setJobDetails(tmp);
}

function onKeySubKeyValueChange(_key, _sub_key, _value) {
    let tmp = { ...jobDetails };
    tmp[_key][_sub_key] = _value;
    setJobDetails(tmp);
}

function removeItemFromList(_key, id) {
    let tmp = { ...jobDetails };
    tmp[_key] = tmp[_key].filter(item => item['id'] !== id);
    setJobDetails(tmp);
}

function addItemToList(_key, item) {
    let tmp = { ...jobDetails };
    tmp[_key].push(item);
    setJobDetails(tmp);
}

function handleSoftSkillExpChange(min, max) {
    setNewSoftSkillExp({ min, max });
}

function handleHardSkillExpChange(min, max) {
    setNewHardSkillExp({ min, max });
}

function handleAddNewSoftSkill() {
    const expString = `${newSoftSkillExp.min}-${newSoftSkillExp.max} years`;
    addItemToList('softSkill', { id: new Date().getTime(), skill: { option: newSoftSkill.option, exp: expString }, explevel: newSoftSkill.explevel });
    setShowNewSoftSkillInput(false);
    setNewSoftSkill({ option: "", exp: "", explevel: "" });
    setNewSoftSkillExp({ min: 0, max: 2 });
}

function handleAddNewHardSkill() {
    const expString = `${newHardSkillExp.min}-${newHardSkillExp.max} years`;
    addItemToList('hardSkill', { id: new Date().getTime(), skill: { option: newHardSkill.option, exp: expString }, explevel: newHardSkill.explevel });
    setShowNewHardSkillInput(false);
    setNewHardSkill({ option: "", exp: "", explevel: "" });
    setNewHardSkillExp({ min: 0, max: 2 });
}

function onJobMatchesModalResp(_cmd, _value){
    if(_cmd === 'close'){
        setShowJobMatchesModal(false)
    }
}

return (
    <div className="container-fluid">
        {loading === false?
            <>
                {jobDetails !== null?
                    <div className="card p-2">
                        <div className="row mt-2">
                            <div className="col-md-4">
                                <h4>{jobDetails['company'][0]['name']}</h4>
                                <h6>{jobDetails['jobTitle']['option']}</h6>
                                <ol className="list-group list-group-numbered">
                                    <li className="list-group-item">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold text-nowrap">Location</div>
                                        </div>
                                        <input defaultValue={jobDetails['locationName']} type="text" className="form-control w-100 border-bottom border-dark" placeholder="text" onChange={(e) => onKeyValueChange('locationName', e.target.value)} />
                                    </li>
                                    <li className="list-group-item">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold text-nowrap">Remote Work</div>
                                        </div>
                                        <select onChange={(e) => onKeyValueChange('remotely', e.target.value === 'true')}
                                            value={jobDetails['remotely'] ? 'true' : 'false'} className="form-select">
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold text-nowrap">Company Size</div>
                                        </div>
                                        <input defaultValue={jobDetails['campanySize']} type="text" className="form-control w-100 border-bottom border-dark" placeholder="text" onChange={(e) => onKeyValueChange('campanySize', e.target.value)} />
                                    </li>
                                    <li className="list-group-item d-flex align-items-center">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold text-nowrap">Roles: {jobDetails['role'].length}</div>
                                            {showNewRoleInput && (
                                                <Card className="mb-3 w-100">
                                                    <Card.Body>
                                                        <div className="d-flex align-items-center">
                                                            <input value={newRole} type="text" className="form-control w-100 border-bottom border-dark" placeholder="New Role" onChange={(e) => setNewRole(e.target.value)} />
                                                            <Button onClick={() => {
                                                                addItemToList('role', { id: new Date().getTime(), option: newRole });
                                                                setShowNewRoleInput(false);
                                                                setNewRole("");
                                                            }} className="ms-2">Add</Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {jobDetails['role'].map((role, index) =>
                                                <div key={index} id={`role-${role['id']}-${index}`} className="d-flex align-items-center">
                                                    <input defaultValue={role['option']} type="text" className="form-control w-100 border-bottom border-dark" placeholder="text" onChange={(e) => onKeySubKeyValueChange('role', role['_id'], e.target.value)} />
                                                    <span onClick={() => removeItemFromList('role', role['id'])} type="button" className="badge border text-danger">X</span>
                                                </div>
                                            )}
                                            <span onClick={() => setShowNewRoleInput(!showNewRoleInput)} className="float-end fs-3 text-success">+</span>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold text-nowrap">Education: {jobDetails['education'].length}</div>
                                            {showNewEducationInput && (
                                                <Card className="mb-3 w-100">
                                                    <Card.Body>
                                                        <div className="d-flex align-items-center">
                                                            <input value={newEducation} type="text" className="form-control w-100 border-bottom border-dark" placeholder="New Education" onChange={(e) => setNewEducation(e.target.value)} />
                                                            <Button onClick={() => {
                                                                addItemToList('education', { id: new Date().getTime(), option: newEducation });
                                                                setShowNewEducationInput(false);
                                                                setNewEducation("");
                                                            }} className="ms-2">Add</Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {jobDetails['education'].map((edu, index) =>
                                                <div key={index} id={`role-${edu['id']}-${index}`} className="d-flex align-items-center">
                                                    <input defaultValue={edu['option']} type="text" className="form-control w-100 border-bottom border-dark" placeholder="text" onChange={(e) => onKeySubKeyValueChange('education', edu['_id'], e.target.value)} />
                                                    <span onClick={() => removeItemFromList('education', edu['id'])} type="button" className="badge border text-danger">X</span>
                                                </div>
                                            )}
                                            <span onClick={() => setShowNewEducationInput(!showNewEducationInput)} className="float-end fs-3 text-success">+</span>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-md-4">
                                <h6>
                                    Soft Skills: {jobDetails['softSkill'].length}
                                    <span onClick={() => setShowNewSoftSkillInput(!showNewSoftSkillInput)} className="float-end fs-3 text-success">+</span>
                                </h6>
                                <ol className="list-group list-group-numbered">
                                    {showNewSoftSkillInput && (
                                        <Card className="mb-3 w-100">
                                            <Card.Body>
                                                <div className="d-flex flex-column">
                                                    <input value={newSoftSkill.option} type="text" className="form-control w-100 border-bottom border-dark mb-2" placeholder="New Soft Skill" onChange={(e) => setNewSoftSkill({ ...newSoftSkill, option: e.target.value })} />
                                                    <input value={newSoftSkill.explevel} type="text" className="form-control w-100 border-bottom border-dark mb-2" placeholder="Experience Level" onChange={(e) => setNewSoftSkill({ ...newSoftSkill, explevel: e.target.value })} />
                                                    <label>Experience: {newSoftSkillExp.min} - {newSoftSkillExp.max} years</label>
                                                    <input type="range" min="0" max="10" value={newSoftSkillExp.min} onChange={(e) => handleSoftSkillExpChange(Number(e.target.value), newSoftSkillExp.max)} />
                                                    <input type="range" min="0" max="10" value={newSoftSkillExp.max} onChange={(e) => handleSoftSkillExpChange(newSoftSkillExp.min, Number(e.target.value))} />
                                                    <Button onClick={handleAddNewSoftSkill} className="mt-2">Add</Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )}
                                    {jobDetails['softSkill'].map((skill, index) =>
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-start border-bottom border-dark">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{skill['skill']['option']}</div>
                                                {skill['skill']['exp']}
                                            </div>
                                            <span onClick={() => removeItemFromList('softSkill', skill['id'])} type="button" className="badge border text-danger">X</span>
                                        </li>
                                    )}
                                </ol>
                            </div>
                            <div className="col-md-4">
                                <h6>
                                    Hard Skills: {jobDetails['hardSkill'].length}
                                    <span onClick={() => setShowNewHardSkillInput(!showNewHardSkillInput)} className="float-end fs-3 text-success">+</span>
                                </h6>
                                <ol className="list-group list-group-numbered">
                                    {showNewHardSkillInput && (
                                        <Card className="mb-3 w-100">
                                            <Card.Body>
                                                <div className="d-flex flex-column">
                                                    <input value={newHardSkill.option} type="text" className="form-control w-100 border-bottom border-dark mb-2" placeholder="New Hard Skill" onChange={(e) => setNewHardSkill({ ...newHardSkill, option: e.target.value })} />
                                                    <input value={newHardSkill.explevel} type="text" className="form-control w-100 border-bottom border-dark mb-2" placeholder="Experience Level" onChange={(e) => setNewHardSkill({ ...newHardSkill, explevel: e.target.value })} />
                                                    <label>Experience: {newHardSkillExp.min} - {newHardSkillExp.max} years</label>
                                                    <input type="range" min="0" max="10" value={newHardSkillExp.min} onChange={(e) => handleHardSkillExpChange(Number(e.target.value), newHardSkillExp.max)} />
                                                    <input type="range" min="0" max="10" value={newHardSkillExp.max} onChange={(e) => handleHardSkillExpChange(newHardSkillExp.min, Number(e.target.value))} />
                                                    <Button onClick={handleAddNewHardSkill} className="mt-2">Add</Button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )}
                                    {jobDetails['hardSkill'].map((skill, index) =>
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-start border-bottom border-dark">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{skill['skill']['option']}</div>
                                                {skill['skill']['exp']}
                                            </div>
                                            <span onClick={() => removeItemFromList('hardSkill', skill['id'])} type="button" className="badge border text-danger">X</span>
                                        </li>
                                    )}
                                </ol>
                            </div>
                            <div className="col-md-12">
                                <h6>Job Description</h6>
                                <textarea value={jobDetails['jobDesc']} onChange={(e) => onKeyValueChange('jobDesc', e.target.value)} className="form-control" style={{ 'minHeight': '230px' }}></textarea>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <div>
                                <button onClick={() => setShowJobMatchesModal(true)} className="btn btn-sm btn-outline-secondary">Show Matches</button>
                                <button onClick={() => funReMatchingAlgorythm()} className="btn btn-outline-info btn-small ms-1">Run Matching</button>
                            </div>
                            <Button onClick={onUpdateClick} className="btn btn-sm float-end" disabled={btnSaveText !== baseSaveText} variant="primary">{btnSaveText}</Button>
                        </div>
                    </div>
                : null}
            </>
            : <h1 className="mt-4 mb-4 text-center opacity-60">Loading...</h1>
        }
        <JobMatchesModal _show={showJobMatchesModal} _job_id={jobid} _onResp={onJobMatchesModalResp}/>
    </div>
);
};

export default JobDetailsPage;
