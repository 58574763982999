import { postData } from "./requests";

export const getPromptsById = (_doc_id) => {
    const url = 'ai-prompts/by-id';
    const requestData = {
        'doc_id': _doc_id,
    };
    return postData(url, requestData)
}

export const getPromptByKey = (_job_id) => {
    const url = 'ai-prompts/by-key';
    const requestData = {
        'key': _job_id
    };
    return postData(url, requestData)
}

export const getPromptKeys = () => {
    const url = 'ai-prompts/get-keys';
    const requestData = {};
    return postData(url, requestData)
}

export const updatePrompt = (_record) => {
    const url = 'ai-prompts/update';
    return postData(url, _record)
}
